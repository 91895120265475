import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { listQueuedTests, createTestFromQueue } from '../api';
import { clearErrors, setError, setSuccess } from '../alerts';
import { Button } from '@lexcelon/react-util';
import { Typography } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const TestQueue = ({ instrumentSerialNumber, onSuccess }) => {
  const [tests, setTests] = useState([]);
  const [isLoadingTestId, setIsLoadingTestId] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    refreshList();
    return () => clearErrors();
  }, []);

  const onTestSelection = (test) => {
    setIsLoadingTestId(test.getId());
    createTestFromQueue({ id: test.id, instrumentSerialNumber }).then(test => {
      setSuccess('Test created successfully');
      onSuccess(test);
    }).catch(error => {
      setError(error ?? 'Failed to create test from queue');
    }).finally(() => {
      setIsLoadingTestId(null);
    });
  };

  const refreshList = () => {
    setIsRefreshing(true);
    listQueuedTests().then((tests) => {
      setTests(tests);
    }).catch(error => {
      setError(error ?? 'Failed to list queued tests');
    }).finally(() => setIsRefreshing(false));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Button style={{ marginBottom: 10 }} isLoading={isRefreshing} onClick={refreshList}>
        Refresh Queued Tests
        <RefreshIcon style={{ marginLeft: 5 }} />
      </Button>


      {tests.length === 0 ? (
        <Typography>No tests in queue.</Typography>
      ) : (
        tests.map((test, index) => (
          <Button
            secondary
            key={index}
            style={{ width: '100%', padding: '20px', marginTop: index > 0 ? 10 : 0 }}
            onClick={() => onTestSelection(test)}
            disabled={isLoadingTestId != null}
            isLoading={isLoadingTestId === test.getId()}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography style={{ fontWeight: 'bold' }}>{test.getTestType()?.getName()}</Typography>
              <Typography>For {test.getAnimal()?.getName()} ({test.getAnimal()?.getOwnerFullName()})</Typography>
              {test.getTestType()?.getIsDemo() && <Typography style={{ textTransform: 'uppercase', color: 'red', fontWeight: 'bold', backgroundColor: 'white', padding: 5, borderRadius: 3, marginTop: 5 }}>TRAINING TEST</Typography>}
            </div>
          </Button>
        ))
      )}
    </div>
  );
};

TestQueue.propTypes = {
  instrumentSerialNumber: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TestQueue;